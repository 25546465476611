import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsOfService = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <div className="terms">
      <h1>Terms of Service</h1>
      <p>Welcome to Astrolab’s Terms of Use agreement. For purposes of this agreement, “Site” refers to the Company’s application, which can be accessed at support[at]astrolab.app.  “Service” refers to the Company’s services accessed via the Site. The terms “we,” “us,” and “our” refer to Astrolab and Joanna Liu (hereinafter referred to as "the Company"). “You” refers to you, as a user of our Site or our Service. </p>
      <p>The following Terms of Use apply when you view or use the Service by accessing the Service through clicking on the application on your mobile device.</p>
      <p>Please review the following terms carefully. By accessing or using the Service, you signify your agreement to these Terms of Use. If you do not agree to be bound by these Terms of Use in their entirety, you may not access or use the Service.  </p>

      <h2>PRIVACY POLICY</h2>
      <p>The Company respects the privacy of its Service users. Please refer to the Company’s Privacy Policy (astrolab.app/privacy) which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Service, you signify your agreement to the Privacy Policy as well as these Terms of Use.</p>

      <p>When we store and accumulate user information, we will handle it in accordance with our Privacy Policy and use it for the following purposes. (1) Service operation (including the provision of any information from the company to the user). (2) To inform about our services (not limited to the services) or products, services, etc. of advertisers and partner companies that we deem beneficial to users. (3) to the user regarding matters that significantly affect the operation of the service (including, but not limited to, significant changes to the contents of the service and suspension). (4) To contact the user for consent regarding the handling of their information. (5) Preparation of statistical information on the usage status of the service, publication on the service or the company's website, sale to third parties, and other provision.</p>

      <h2>REGISTRATION; RULES FOR USER CONDUCT AND USE OF THE SERVICE</h2>
      <p>You need to be at least 13 years old and a resident of the United States to register for and use the Service.</p>
      <p>If you are a user who signs up for the Service, you will create a personalized account which includes a unique username and a password to access the Service and to receive messages from the Company. You agree to notify us immediately of any unauthorized use of your password and/or account. The Company will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your member name, password and/or account.</p>

      {/* <h2>USE RESTRICTIONS</h2> */}
      <h2>USE RESTRICTIONS</h2>
      <p>Your permission to use the Site is conditioned upon the following use, posting and conduct restrictions: You agree that you will not under any circumstances:</p>

      <p>- access the Service for any reason other than your personal, non-commercial use solely as permitted by the normal functionality of the Service</p><p>
      - collect or harvest any personal data of any user of the Site or the Service </p><p>
      - use the Site or the Service for the solicitation of business in the course of trade or in connection with a commercial enterprise</p><p>
      - distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly-available searchable indices but retain the right to revoke this permission at any time on a general or specific basis)</p><p>
      - use the Service for any unlawful purpose or for the promotion of illegal activities</p><p>
      - attempt to, or harass, abuse, or harm another person or group</p><p>
      - use another user’s account without permission</p><p>
      - intentionally allow another user to access your account </p><p>
      - provide false or inaccurate information when registering an account</p><p>
      - interfere or attempt to interfere with the proper functioning of the Service</p><p>
      - make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure</p><p>
      - bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data </p><p>
      - circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service or </p><p>
      - publish or link to malicious content of any sort, including that intended to damage or disrupt another user’s browser or computer.
      </p>
      <p>- acquire, disclose, falsify, or divulge information that can identify a specific individual, such as name, Name, Telephone Number, e-mail address, or address, or non-public information, trade secrets, or confidential information of companies, organizations, etc., without the permission of the person or the right holder, or any act that may result in such disclosure.</p>
      <p>- commit acts that are against other users' will, such as stalk other users relentlessly or continously and intentionally disconnect and talk down or offense other users</p>
      <p>- provide benefits to social forces and other cooperation</p>
      <p>- promote, announce, and solicit transactions similar to pyramid schemes, MLM, network business and other chain transactions, and acts of such transactions.</p>
      <p>- conduct for the purpose of profit without the company's permission</p>
      <p>- promote, announce, and solicit religious and political activities</p>
      <p>- hold multiple user accounts or share user accounts with multiple people without the company's permission</p>
      <p>- share a user account with multiple people or with multiple devices</p>
      <p>- act as someone else with unauthorized access and mislead</p>
      <p>- burden the server or network for the operation of the service</p>
      <p>- alter, damage, disassemble, decompile, or reverse engineer the service</p>
      <p>- transfer or make use of the user account, content in the service, or any part of the rights or interests available to the user in the service to a third party in exchange for cash or other property interests, as well as advertising, notification, and solicitation thereof</p>
      <p>- make unreasonable requests to the company, such as repeating similar or similar inquiries more than necessary</p>
      <p>- any use the company deems inappropiate</p>

      <p>We prohibit acts that fall under the following: 1. Acts that cause trouble to others. 2. Slander to others or act against public order and morals. 3. Exchange of personal information. Users who intentionally repeat the relevant actions will be blocked from accessing the app. </p>

      {/* <p>If any content violates this policy, our Terms of Service, or for any other reason, The Company reserves the right to remove the content at our sole discretion. </p> */}

      {/* <h2>POSTING AND CONDUCT RESTRICTIONS</h2> */}
      <h2>POSTING AND CONDUCT RESTRICTIONS</h2>
      <p>When you create your own personalized account, you may be able to provide poetry lines (“User Content”) to the Service. You are solely responsible for the User Content that you post, upload, link to or otherwise make available via the Service. </p>
      <p>You agree that we are only acting as a passive conduit for your online distribution and publication of your User Content. The Company, however, reserves the right to remove any User Content from the Service at its sole discretion.</p>

      <p>We grant you permission to use and access the Service, subject to the following express conditions surrounding User Content. You agree that failure to adhere to any of these conditions constitutes a material breach of these Terms.</p>

      <p>By transmitting and submitting any User Content while using the Service, you agree as follows:
      </p>

      <p>- You are solely responsible for your account and the activity that occurs while signed in to or while using your account</p><p>
      - You will not post information that is malicious, libelous, false or inaccurate, discriminate or slander the company or a third party, damage the reputation or credibility of the company or the service</p>
      <p>
      - You will not post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable and offensive</p><p>
      - You retain all ownership rights in your User Content but you are required to grant the following rights to the Site and to users of the Service as set forth more fully under the “License Grant” and “Intellectual Property” provisions below: When you upload or post User Content to the Site or the Service, you grant to the Site a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform that Content in connection with the provision of the Service and you grant to each user of the Service, a worldwide, non-exclusive, royalty-free license to access your User Content through the Service, and to use, reproduce, distribute, prepare derivative works of, display and perform such Content to the extent permitted by the Service and under these Terms of Use</p><p>
      - You will not submit content that is copyrighted or subject to third party proprietary rights, including privacy, publicity, trade secret, or others, unless you are the owner of such rights or have the appropriate permission from their rightful owner to specifically submit such content</p><p>
      - You hereby agree that we have the right to determine whether your User Content submissions are appropriate and comply with these Terms of Service, remove any and/or all of your submissions, and terminate your account with or without prior notice.
      </p>
      <p>You understand and agree that any liability, loss or damage that occurs as a result of the use of any User Content that you make available or access through your use of the Service is solely your responsibility. The Site is not responsible for any public display or misuse of your User Content.</p>

      <p>The Site does not, and cannot, pre-screen or monitor all User Content. However, at our discretion, we, or technology we employ, may monitor and/or record your interactions with the Service or with other Users.
      </p>

      <p> If the user wishes to preserve user information and User Content, the user shall take the backup by his / her own responsibility. When a user withdraws from the service or the company terminates the operation of the service, the company may, at its discretion, delete all user information and User Content, and will not respond to requests for return, etc.</p>

      {/* <h2>ONLINE CONTENT DISCLAIMER</h2> */}
      <h2>ONLINE CONTENT DISCLAIMER</h2>
      <p>Opinions, advice, statements, offers, or other information or content made available through the Service, but not directly by the Site, are those of their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content. </p>

      <p>We do not guarantee the accuracy, completeness, or usefulness of any information on the Site or the Service nor do we adopt nor endorse, nor are we responsible for, the accuracy or reliability of any opinion, advice, or statement made by other parties. We take no responsibility and assume no liability for any User Content that you or any other user or third party posts or sends via the Service. Under no circumstances will we be responsible for any loss or damage resulting from anyone’s reliance on information or other content posted on the Service, or transmitted to users.</p>

      <p>Though we strive to enforce these Terms of Use, you may be exposed to User Content that is inaccurate or objectionable when you use or access the Site or the Service. We reserve the right, but have no obligation, to monitor the materials posted in the public areas of the Site or the Service or to limit or deny a user’s access to the Service or take other appropriate action if a user violates these Terms of Use or engages in any activity that violates the rights of any person or entity or which we deem unlawful, offensive, abusive, harmful or malicious. The Company shall have the right to remove any material that in its sole opinion violates, or is alleged to violate, the law or this agreement or which might be offensive, or that might violate the rights, harm, or threaten the safety of users or others.  Unauthorized use may result in criminal and/or civil prosecution under Federal, State and local law. If you become aware of a misuse of our Service or violation of these Terms of Use, please contact us support[at]astrolab.app.</p>

      {/* Your use of IRL is at your own risk. We specifically disclaim any and all warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement, and any warranties arising out of course of dealing or usage of trade.

We do not review User Content in advance of its posting. IRL takes no responsibility and assumes no liability for any User Content that you or any other person or third party posts or sends using our Service. You understand and agree that you may be exposed to User Content that’s inaccurate, offensive, indecent, objectionable, inappropriate for children, or otherwise unsuited to your purpose.       */}

      <h2>LINKS TO OTHER SITES AND/OR MATERIALS</h2>
      <p>As part of the Service, we may provide you with convenient links to third party website(s) (“Third Party Sites”) as well as content or items belonging to or originating from third parties (the “Third Party Applications, Software or Content”). These links are provided as a courtesy to Service subscribers. We have no control over Third Party Sites or Third Party Applications, Software or Content or the promotions, materials, information, goods or services available on these Third Party Sites or Third Party Applications, Software or Content. Such Third Party Sites and Third Party Applications, Software or Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness, and we are not responsible for any Third Party Sites accessed through the Site or any Third Party Applications, Software or Content posted on, available through or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other policies of or contained in the Third Party Sites or the Third Party Applications, Software or Content. Inclusion of, linking to or permitting the use or installation of any Third Party Site or any Third Party Applications, Software or Content does not imply our approval or endorsement. If you decide to leave the Site and access the Third Party Sites or to use or install any Third Party Applications, Software or Content, you do so at your own risk and you should be aware that our terms and policies, including these Terms of Use, no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any Third Party Site to which you navigate from the Site or relating to any applications you use or install from the Third Party Site.</p>

      <p>Link to Terms and Conditions of third party service providers used by the app: <br/>
      -<a href="https://developers.google.com/admob/terms">AdMob</a> <br/>
        -<a href="https://firebase.google.com/terms/analytics">Google Analytics for Firebase</a> <br/>
      </p>

      {/* <h2>COPYRIGHT COMPLAINTS AND COPYRIGHT AGENT</h2> */}

      <h2>LICENSE GRANT</h2>
      <p>By posting any User Content via the Service, you expressly grant, and you represent and warrant that you have a right to grant, to the Company a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness as contained in your User Content, if applicable, in whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use in connection with the Service.</p>

      <h2>INTELLECTUAL PROPERTY</h2>
      <p>You acknowledge and agree that we and our licensors retain ownership of all intellectual property rights of any kind related to the Service, including applicable copyrights, trademarks and other proprietary rights. Other product and company names that are mentioned on the Service may be trademarks of their respective owners. We reserve all rights that are not expressly granted to you under these Terms of Use.</p>

      <h2>INDEMNITY</h2>
      <p>The company does not guarantee that the service does not infringe the rights of third parties and that the execution of the service does not infringe the rights of third parties. The Company shall not be involved in any transactions conducted by the user through the service, and does not guarantee the accuracy of any information through the service. The user shall resolve any disputes arising between the user and other users at his / her own responsibility and judgment.</p>

      <p>The Company shall not be liable even if the user suffers damage due to failure or loss of the smartphone terminal used by the user, attack by malware, spyware, virus, hacking, etc. from a third party, mismanagement of the user account, error in use or use by a third party.</p>

      <p>If your user account is improperly acquired or your device or user account is likely to be used by a third party, you shall take all measures reasonably deemed necessary to avoid causing damage to the company or a third party, such as promptly withdrawing or reporting to the company.</p>

      <p>In such circumstances, you agree we are entitled to payment from you for any judgment, our reasonable legal fees and all costs we incur to defend ourselves. Our right to indemnification survives any cancellation, termination or suspension of your account.</p>

      <h2>USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM</h2>
      <p>For contractual purposes, you: (a) consent to receive communications from us in an electronic form via the email address you have submitted; and (b) agree that all Terms of Use, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. The foregoing does not affect your non-waivable rights.</p>

      <h2>WARRANTY DISCLAIMER</h2>
      <p>THE SERVICE, IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, REGARDING THE SERVICE INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED FROM THE SERVICE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU.</p>

      <h2>LIMITATION OF DAMAGES</h2>
      <p>RELEASE TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SITE, THE SERVICE, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE,  OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM: (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY TO USE THE SERVICE; (C) THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH USE OR WITH ANY OTHER USER OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.</p>

      <p>If you have a dispute with one or more users, a restaurant or a merchant of a product or service that you review using the Service, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>

      <p>If you are a California resident using the Service, you may specifically waive California Civil Code §1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”</p>

      <p>You should be aware that there are certain things that will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.</p>


      <p>If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</p>

      <p>Along the same lines, cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, cannot accept responsibility.</p>

      <p>With respect to ’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>

      <p>At some point, we may wish to update the app. The app is currently available on iOS – the requirements for system(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. does not promise that it will always update the app so that it is relevant to you and/or works with the iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>

      <p>You should be aware that there are certain things that will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.</p>

      <h2>SOFTWARE UPDATES</h2>
      <p>If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</p>

      <p>Along the same lines, we cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, we cannot accept responsibility.</p>

      <p>When you’re using the app, it’s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. The Company accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.</p>

      <p>At some point, we may wish to update the app. The app is currently available on iOS – the requirements for system(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. The Company does not promise that it will always update the app so that it is relevant to you and/or works with the iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>

      {/* <h2>MODIFICATION OF TERMS OF USE</h2> */}
      <h2>MODIFICATION OF TERMS OF USE</h2>
      <p>We can amend these Terms of Use at any time and will update these Terms of Use in the event of any such amendments. It is your sole responsibility to check the Site from time to time to view any such changes in this agreement. Your continued use of the Site or the Service signifies your agreement to our revisions to these Terms of Use. We will endeavor to notify you of material changes to the Terms by sending an email to the email address you provided to us upon registration. For this additional reason, you should keep your contact and profile information current. Any changes to these Terms (other than as set forth in this paragraph) or waiver of our rights hereunder shall not be valid or effective except in a written agreement bearing the physical signature of one of our officers. No purported waiver or modification of this agreement on our part via telephonic or email communications shall be valid.</p>

      {/* <h2>GENERAL TERMS</h2> */}
      <h2>GENERAL TERMS</h2>
      <p>If any part of this Terms of Use agreement is held or found to be invalid or unenforceable, that portion of the agreement will be construed as to be consistent with applicable law while the remaining portions of the agreement will remain in full force and effect. Any failure on our part to enforce any provision of this agreement will not be considered a waiver of our right to enforce such provision. Our rights under this agreement survive any transfer or termination of this agreement.</p>

      <p>You agree that any cause of action related to or arising out of your relationship with the Company must commence within ONE year after the cause of action accrues. Otherwise, such cause of action is permanently barred.</p>

      <p>These Terms of Use and your use of the Site are governed by the federal laws of the United States of America and the laws of the State of California, without regard to conflict of law provisions.</p>

      <p>We may assign or delegate these Terms of Service and/or our Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without our prior written consent, and any unauthorized assignment or delegation by you is void.</p>

      <p>YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND THE TERMS OF USE, AND WILL BE BOUND BY THESE TERMS AND CONDITIONS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER WITH THE PRIVACY POLICY AT (ASTROLAB.APP/PRIVACY) REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.</p>

      <p>Effective date: 1/1/2021</p>

    </div>

    </Layout>
)

export default TermsOfService